<template>
    <div class="ml-10 mr-10">
        <TitleBar>
            {{ $t("menu.accounts") }}
            <v-btn
                class="mx-2"
                color="secondary"
                fab
                small
                to="/accounts/new"
            >
                <v-icon>
                    mdi-plus
                </v-icon>
            </v-btn>
        </TitleBar>

        <List :fields="['name', 'currency', 'bank_name']" api-key="accounts" api-url="/accounts"></List>

    </div>
</template>

<script>

import List from "../../components/List";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Dashboard",
    components: {TitleBar, List},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.accounts"),
                to: "/accounts",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>